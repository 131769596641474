const SelectionBoard: React.FC<{
  selected: string | undefined;
  items: string[];
  setSelected: (item: string) => void;
  columns: number;
  textSize: string;
}> = ({ items, selected, setSelected, columns, textSize }) => {
  let gridClass = "";
  switch (columns) {
    case 2:
      gridClass = "grid-cols-2";
      break;
    case 3:
      gridClass = "grid-cols-3";
      break;
    case 4:
      gridClass = "grid-cols-4";
      break;
    case 5:
      gridClass = "grid-cols-5";
      break;
    default:
      gridClass = "grid-cols-1"; // Fallback to one column if none specified
  }

  return (
    <div className={`w-1/2 min-w-[360px] grid ${gridClass} gap-4`}>
      {items.map((item, index) => (
        <div
          id={`item${index + 1}`}
          key={item}
          className={`p-4 ${
            item === selected ? "bg-green-50" : "bg-white"
          } rounded-lg cursor-pointer`}
          onClick={() => setSelected(item)}
        >
          <div className="flex items-center gap-2">
            {item === selected ? (
              <svg
                className="w-6 h-6 text-green-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6 text-blue-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M5 12h14m-7 7V5"
                />
              </svg>
            )}
            <div className={`text-gray-800 ${textSize} font-gooper`}>
              {item}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectionBoard;
