import React, { useEffect, useState } from "react";
import TeamOnboardingPage from "./TeamOnboardingPage";
import {
  PaymentOption,
  SubscriptionOption,
} from "../../models/SubscriptionOption";
import { useTeams } from "../../contexts/TeamContext";
import SubscriptionCard from "./SubscriptionCard";
import { TeamOnboardingStep } from "./TeamOnboarding";

const TeamAddSubscription: React.FC<{
  gotSubscription: (sub: SubscriptionOption, price: PaymentOption) => void;
  progress: number;
  setStep: (step: TeamOnboardingStep) => void;
  isTopLoading: boolean;
}> = ({ gotSubscription, progress, setStep, isTopLoading }) => {
  const teamService = useTeams();

  const [subscriptions, setSubscriptions] = useState<SubscriptionOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [yearly, setYearly] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserTeams = async () => {
      try {
        const fetchedSubscriptionOptions =
          await teamService.subscriptionOptionRepo.getList(
            teamService.subscriptionOptionPath()
          );
        fetchedSubscriptionOptions.sort((a, b) => a.index - b.index);
        setSubscriptions(fetchedSubscriptionOptions);
      } catch (error) {
        setError("Failed to fetch teams. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserTeams();
  }, [teamService]);

  const handleSelected = async (
    subscription: SubscriptionOption,
    price: PaymentOption
  ) => {
    gotSubscription(subscription, price);
    if (subscription.seats > 1) {
      setStep(TeamOnboardingStep.Members);
    }
  };

  const handleContactUsClick = () => {
    window.location.href = "mailto:hello@easybeam.ai";
  };

  return (
    <TeamOnboardingPage
      error={error}
      title={"Free 30 day trial on all plans"}
      headline="Choose your plan"
      message={``}
      isLoading={isLoading || isTopLoading}
      progress={progress}
      setError={setError}
      didClickBack={() => setStep(TeamOnboardingStep.Name)}
      highlightTitle={true}
    >
      <div className="h-5 justify-center items-center gap-2 inline-flex">
        <div className="flex-col justify-start items-start gap-0.5 inline-flex">
          <div className="text-gray-900 text-sm font-medium font-gooper leading-none">
            Monthly
          </div>
        </div>
        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={yearly}
            onChange={() => setYearly(!yearly)}
            className="sr-only peer"
          />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
        <div className="flex-col justify-start items-start gap-0.5 inline-flex">
          <div className="text-gray-900 text-sm font-medium font-gooper leading-none">
            Yearly
          </div>
        </div>
        <div className="text-gray-500 text-xs font-light leading-none">
          Save 33%
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-stretch">
        {subscriptions
          .filter((s) => !s.isHidden)
          .map((sub) => (
            <SubscriptionCard
              features={sub.features}
              key={sub.id}
              subscription={sub}
              yearly={yearly}
              selected={handleSelected}
              highlightFirstFeature={sub.highlightFirstFeature}
            />
          ))}
      </div>
      <div className="min-h-20 max-w-[964px] p-1 bg-white rounded-lg shadow-sm border border-gray-200 grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
        <div className="text-gray-800 text-2xl font-medium font-gooper leading-9">
          Enterprise - ready
        </div>
        <div className="text-gray-500 text-sm font-normal leading-tight">
          Reach out for more seats, special features & customization.
        </div>
        <button
          onClick={() => handleContactUsClick()}
          className="h-12 px-5 py-3 bg-white rounded-full justify-center items-center gap-2 "
        >
          <a
            href="mailto:hello@easybeam.ai"
            className="text-blue-500 text-base font-medium justify-center font-gooper leading-normal flex gap-2 flex-row hover:text-blue-700"
            style={{ textDecoration: "none" }} // Ensures that the link does not look like a typical hyperlink
          >
            I want more
            <svg
              className="w-6 h-6 text-blue-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </a>{" "}
        </button>
      </div>
      <div className="text-gray-500 pt-6 text-xs font-normal leading-tight -mb-10">
        Note: Easybeam only manages your Ai subscription with respective
        providers.
      </div>
    </TeamOnboardingPage>
  );
};

export default TeamAddSubscription;
