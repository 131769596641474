import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import OnboardingPage from "./OnboardingPage";
import SelectionBoard from "./SelectionBoard";
import { STORAGE_KEYS } from "../../utils/StorageKeys";
import { useUserEvent } from "../../contexts/UserEventContext";
import { useNavigate } from "react-router-dom";
import { NextButton } from "./NextButton";

const methods = [
  "Recommendation",
  "Linkedin",
  "Reddit",
  "StackOverflow",
  "Instagram",
  "Twitter / X / Whatever it is",
  "Youtube",
  "Our blog",
];

const UserRefferal: React.FC<{
  skipSurvey: () => void;
  progress: number;
}> = ({ skipSurvey, progress }) => {
  const navigate = useNavigate();
  const authService = useAuth();
  const events = useUserEvent();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<string>();

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const currentUser = await authService.currentUser();
      if (!currentUser) return;

      await events.createEvent(authService, "OnboardingEnd");

      await authService.userRepo.update(
        { referral: selectedMethod ?? "SKIPPED" },
        authService.userPath(),
        currentUser.id
      );
      setTimeout(() => {
        navigate("/postonboarding");
      }, 2000);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <OnboardingPage
      error={error}
      title={
        localStorage.getItem(STORAGE_KEYS.INVITED_TEAM_ID) != null
          ? "Last question"
          : "We wanna know"
      }
      headline="How'd you hear about us?"
      message="Please tell us so we don’t have to message people on Linkedin."
      isLoading={isLoading}
      progress={progress}
      setError={() => undefined}
      didSkip={skipSurvey}
    >
      <SelectionBoard
        items={methods}
        selected={selectedMethod}
        setSelected={setSelectedMethod}
        columns={2}
        textSize="text-base"
      />
      <NextButton onClick={handleSubmit} />
    </OnboardingPage>
  );
};

export default UserRefferal;
