import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { STORAGE_KEYS } from "../../utils/StorageKeys";
import FailureModal from "../FailureModal";
import AuthFooter from "../Auth/AuthFooter";
import { CommonLoading } from "../Common/CommonLoading";
import { AppPath } from "../../models/AppPath";

const PostOnboarding: React.FC = () => {
  const navigate = useNavigate();
  const authService = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchUserTeams = async () => {
      try {
        const user = await authService.currentUser();
        const teamIdFromInvite = localStorage.getItem(
          STORAGE_KEYS.INVITED_TEAM_ID
        );
        if (teamIdFromInvite && authService.isOnboarded(user)) {
          navigate(`/invites/${teamIdFromInvite}`);
          return;
        }

        if (user!.teams == undefined || user!.inProgressTeam) {
          navigate(`/newteam`);
          return;
        }

        const activeTeamIds = Object.entries(user!.teams!).filter(
          ([_, isActive]) => isActive
        );
        if (user?.currentTeam) {
          navigate(AppPath.team(user!.currentTeam!));
          return;
        } else if (activeTeamIds.length > 0) {
          await authService.userRepo.update(
            { currentTeam: activeTeamIds[0][0] },
            authService.userPath(),
            user!.id!
          );
          navigate(AppPath.team(activeTeamIds[0][0]));
          return;
        }
        console.error("Fell through post-onboarding logic");
        // if they _were_ in a team but were removed, send them to the create team?
        navigate(`/newteam`);
      } catch (error) {
        setError("Failed to fetch teams. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserTeams();
  }, [authService, navigate]);

  return (
    <>
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to Home"}
        shows={error != ""}
        closed={() => console.error("This shoudln't be possible")}
      />

      {isLoading && <CommonLoading />}
      <div className="bg-general-desktop bg-cover bg-center min-h-screen flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center pt-24 gap-4">
          <div className="text-center text-indigo-50 text-9xl font-semibold font-gooper leading-none">
            Post Onboarding
          </div>
          <div className="text-center text-blue-950 text-4xl font-bold font-gooper leading-none">
            We'll get you there.
          </div>
          <div className="text-center text-gray-50 text-lg font-normal leading-relaxed tracking-tight">
            Give us a sec to process this. &nbsp;
          </div>
        </div>
        <AuthFooter textColor="text-gray-50" textHoverColor="text-gray-600" />
      </div>
    </>
  );
};

export default PostOnboarding;
