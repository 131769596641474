import React, { useEffect, useRef, useState } from "react";
import { Team } from "../../models/Team";
import { useNavigate } from "react-router-dom";
import { useTeams } from "../../contexts/TeamContext";
import { useAuth } from "../../contexts/AuthContext";
import { User } from "../../models/User";
import { TeamSubscription } from "../../models/TeamSubscription";
import ActionModal from "../ActionModal";
import { CommonLoading } from "../Common/CommonLoading";

interface TeamSelectorProps {
  currentUser: User | undefined;
  currentTeam: Team | undefined;
  isCollapsed: boolean;
}

const TeamSelector: React.FC<TeamSelectorProps> = ({
  currentTeam,
  isCollapsed,
  currentUser,
}) => {
  const navigate = useNavigate();
  const teamService = useTeams();
  const authService = useAuth();

  const [showSignOut, setShowSignOut] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teams, setTeams] = useState<[Team, TeamSubscription][]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadData = async () => {
      if (!currentUser?.teams) {
        return;
      }

      const activeTeamIds = Object.entries(currentUser!.teams!)
        .filter(([_, isActive]) => isActive)
        .map(([teamId, _]) => {
          return Promise.all([
            teamService.teamRepo.get(teamService.teamPath(), teamId),
            teamService.teamSubscriptionRepo.get(
              teamService.teamSubscriptionPath(),
              teamId
            ),
          ]);
        });

      const allFetchedTeams = await Promise.allSettled(activeTeamIds);
      const aliveFetched = allFetchedTeams.map((p) => {
        if (p.status == "fulfilled") {
          return p.value;
        } else {
          return null;
        }
      });
      const activeTeams = aliveFetched.filter(
        (r) => r?.[0] != null && r?.[1] != null
      ) as [Team, TeamSubscription][];
      setTeams(activeTeams);
    };
    loadData();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleSelectTeam = async (team: Team) => {
    setIsLoading(true);
    await Promise.all([
      authService.userRepo.update(
        { currentTeam: team.id },
        authService.userPath(),
        authService.userId!
      ),
      teamService.fetchCurrentTeamData(team.id!),
    ]);

    setIsLoading(false);
    navigate(`/teams/${team.id!}`);
    setShowDropdown(false);
  };

  const handleSignOut = async () => {
    setShowDropdown(false);
    await authService.signOut();
    navigate(`/bye`);
  };

  const handleCreateTeam = () => {
    navigate(`/newteam`);
  };

  const teamSelector = (
    <div className="p-2 w-full justify-start items-center flex cursor-pointer">
      {isLoading && <CommonLoading />}
      <div className="grow shrink w-full basis-0 justify-start items-center gap-3 flex">
        <div className="flex-col justify-start gap-1 items-start inline-flex">
          <div className="text-gray-400 text-xs font-normal uppercase leading-3">
            Team
          </div>
          <div className="text-gray-700 text-sm font-normal font-gooper leading-none">
            {!isCollapsed ? currentTeam?.name : "DEMO"}
          </div>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="13"
        viewBox="0 0 12 13"
        className="fill-gray-400"
      >
        <g>
          <path d="M9.20114 5.14883C8.98924 5.14878 8.78603 5.06974 8.63622 4.92907L6.00499 2.45929L3.37377 4.92907C3.30006 5.00071 3.21189 5.05784 3.11441 5.09715C3.01692 5.13646 2.91207 5.15715 2.80598 5.15801C2.69988 5.15888 2.59467 5.1399 2.49647 5.10219C2.39827 5.06448 2.30906 5.00879 2.23403 4.93837C2.15901 4.86795 2.09968 4.78421 2.0595 4.69204C2.01933 4.59986 1.99911 4.5011 2.00003 4.40152C2.00095 4.30193 2.02299 4.20351 2.06487 4.11201C2.10675 4.0205 2.16762 3.93774 2.24394 3.86856L5.44008 0.868519C5.58992 0.727913 5.79312 0.648926 6.00499 0.648926C6.21687 0.648926 6.42007 0.727913 6.56991 0.868519L9.76605 3.86856C9.87777 3.97345 9.95384 4.10707 9.98466 4.25254C10.0155 4.39801 9.99965 4.54878 9.93919 4.68581C9.87872 4.82284 9.77633 4.93996 9.64497 5.02238C9.5136 5.10479 9.35914 5.14879 9.20114 5.14883Z" />
          <path d="M6.00499 12.6489C5.79309 12.6489 5.58989 12.5698 5.44008 12.4292L2.24394 9.42913C2.16762 9.35995 2.10675 9.27719 2.06487 9.18568C2.02299 9.09418 2.00095 8.99576 2.00003 8.89618C1.99911 8.79659 2.01933 8.69783 2.0595 8.60566C2.09968 8.51348 2.15901 8.42974 2.23403 8.35932C2.30906 8.2889 2.39827 8.23321 2.49647 8.1955C2.59467 8.15779 2.69988 8.13881 2.80598 8.13968C2.91207 8.14054 3.01692 8.16123 3.11441 8.20054C3.21189 8.23985 3.30006 8.29699 3.37377 8.36862L6.00499 10.8384L8.63622 8.36862C8.78692 8.232 8.98876 8.1564 9.19826 8.15811C9.40776 8.15982 9.60817 8.2387 9.75632 8.37775C9.90447 8.51681 9.9885 8.70492 9.99032 8.90158C9.99214 9.09823 9.9116 9.28768 9.76605 9.42913L6.56991 12.4292C6.4201 12.5698 6.21689 12.6489 6.00499 12.6489Z" />
        </g>
      </svg>
    </div>
  );

  return (
    <div
      ref={wrapperRef}
      className={`w-full h-8 relative inline-block text-lef ${
        isCollapsed ? "invisible" : "visible"
      } `}
    >
      <ActionModal
        title={"Are you sure you wanna sign out?"}
        message={`We'll miss you dearly`}
        backButtonTitle={"Back to Safety"}
        shows={showSignOut}
        closed={() => setShowSignOut(false)}
        action={() => handleSignOut()}
        iconType="close"
        actionTitle="Sign Out"
        style="normal"
      />
      <div
        onClick={() => setShowDropdown(true)}
        className="w-full fill-blue-200 bg-gray-50 rounded border border-blue-50 justify-start items-center flex cursor-pointer"
      >
        {teamSelector}
        {showDropdown && (
          <div className="absolute top-0 w-full text-left border-1 border-gray-300 bg-gray-50 right-0 z-50 rounded-md shadow-lg">
            <button
              onClick={() => setShowDropdown(true)}
              className="w-full fill-gray-300 bg-gray-100 rounded-t justify-start items-center flex cursor-pointer"
            >
              {teamSelector}
            </button>
            <div className={`flex w-full flex-col border-y border-gray-300`}>
              {teams.map((team) => (
                <div
                  key={team[0].id!}
                  className={`${
                    team[0].id == currentTeam?.id ?? ""
                      ? "bg-blue-50"
                      : "bg-gray-50"
                  } py-1 px-1 hover:bg-blue-25 flex flex-col cursor-pointer`}
                  onClick={() => {
                    handleSelectTeam(team[0]);
                  }}
                >
                  <div className="font-gooper text-sm">{team[0].name}</div>
                  <div className="font-normal text-gray-400 text-xs">
                    {team[1].currentEvent?.name}
                  </div>
                </div>
              ))}
            </div>
            <button
              className={`h-6 w-full justify-end items-center flex flex-col bg-gray-100`}
              onClick={() => handleCreateTeam()}
            >
              <div className="px-2 w-full items-center justify-end flow-root">
                <div className="text-blue-500  float-left text-xs font-gooper ">
                  Add Team
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  className="fill-inherit float-right"
                >
                  <path
                    d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456725 3.7039C0.00259969 4.80025 -0.11622 6.00665 0.115291 7.17054C0.346802 8.33442 0.918247 9.40352 1.75736 10.2426C2.59648 11.0818 3.66557 11.6532 4.82946 11.8847C5.99335 12.1162 7.19974 11.9974 8.2961 11.5433C9.39246 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34672 12 7.18669 12 6C11.9982 4.40926 11.3654 2.88421 10.2406 1.75939C9.11579 0.63457 7.59073 0.00183871 6 0ZM8.67916 6.63158H6.63158V8.67916C6.63158 8.84666 6.56504 9.0073 6.44659 9.12575C6.32815 9.24419 6.16751 9.31073 6 9.31073C5.8325 9.31073 5.67185 9.24419 5.55341 9.12575C5.43496 9.0073 5.36842 8.84666 5.36842 8.67916V6.63158H3.32084C3.15334 6.63158 2.99269 6.56504 2.87425 6.44659C2.75581 6.32815 2.68927 6.1675 2.68927 6C2.68927 5.83249 2.75581 5.67185 2.87425 5.5534C2.99269 5.43496 3.15334 5.36842 3.32084 5.36842H5.36842V3.32084C5.36842 3.15334 5.43496 2.99269 5.55341 2.87425C5.67185 2.7558 5.8325 2.68926 6 2.68926C6.16751 2.68926 6.32815 2.7558 6.44659 2.87425C6.56504 2.99269 6.63158 3.15334 6.63158 3.32084V5.36842H8.67916C8.84666 5.36842 9.00731 5.43496 9.12575 5.5534C9.2442 5.67185 9.31074 5.83249 9.31074 6C9.31074 6.1675 9.2442 6.32815 9.12575 6.44659C9.00731 6.56504 8.84666 6.63158 8.67916 6.63158Z"
                    fill="#3062FF"
                  />
                  ;
                </svg>
              </div>
            </button>
            <button
              className={`py-2 px-2 w-full justify-center items-center rounded-b-lg flex flex-col bg-gray-100`}
              onClick={() => setShowSignOut(true)}
            >
              <div className="w-full flow-root">
                <div className="text-gray-400 float-left text-xs ">
                  Sign Out
                </div>
                <div className="float-right flex items-center justify-center w-3 h-3 rounded-full bg-gray-200 text-gray-400">
                  <svg
                    className=" text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 12H4m12 0-4 4m4-4-4-4m3-4h2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-2"
                    />
                  </svg>
                </div>
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamSelector;
